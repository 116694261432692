import { localeDefault, loadRouteTranslations } from "../lang";
import { useConfig } from "vike-react/useConfig";

export default async (pageContext) => {
  const config = useConfig();
  const locale = pageContext.locale || localeDefault.code;

  const translations = await loadRouteTranslations(pageContext.urlOriginal, locale);

  config({
    title: translations.title,
    description: translations.description,
    lang: locale,
  });

  console.log(translations);

  return { translations };
};
